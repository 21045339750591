import * as R from 'ramda';

import { isRecord } from '@common/types/type-predicates';

type IsEqualWithFlatDiffReturn<T> = [
  boolean,
  Partial<T> | undefined,
];

export const isEqualWithFlatDiff = <T extends unknown>(
  modifiedObject: T,
  baseObject: unknown,
): IsEqualWithFlatDiffReturn<T> => {
  if (Array.isArray(modifiedObject)) {
    // include in diff if objects aren't of same type
    if (!Array.isArray(baseObject)) return [false, undefined];
    if (modifiedObject.length < baseObject.length) return [false, modifiedObject];

    const diff = (modifiedObject as unknown[]).filter((value, index) => {
      const isOfSameType = Array.isArray(baseObject);

      if (!isOfSameType || (baseObject?.[index] === undefined && value !== undefined)) return true;

      const [is] = isEqualWithFlatDiff(value, (baseObject as unknown[])[index]);
      return !is;
    });

    return [
      R.isEmpty(diff),
      diff as T,
    ];
  }

  if (modifiedObject && isRecord(modifiedObject)) {
    // include in diff if objects aren't of same type
    if (!isRecord(baseObject)) return [false, undefined];
    const diff = Object.fromEntries(
      Object.entries(modifiedObject).filter(([key, value]) => {
        if (baseObject?.[key] === undefined && value !== undefined) return true;

        const [is] = isEqualWithFlatDiff(value, baseObject[key]);
        return !is;
      }),
    );

    return [
      R.isEmpty(diff),
      diff as T,
    ];
  }

  const emptyDiff = ((): ([] | Record<string, never> | undefined) => {
    if (Array.isArray(modifiedObject)) return [];
    if (typeof modifiedObject === 'object') return {};
    return undefined;
  })();

  return [
    modifiedObject === baseObject,
    emptyDiff as T,
  ];
};
